@import "../../scss/partials/all.scss";

.success-title-text-container {
    text-align: center;
    .title {
        color: black;
        margin-bottom: 40px;
    }

    .success-title {
        font-size: 18px;
        color: $green-success;
        font-weight: bold;
        margin-bottom: 5px;
    }

    .success-subtitle {
        color: $gray-font-light;
        font-size: 14px;
        margin-bottom: 80px;
    }

    .text-bottom {
        color: grey;
        font-size: 13px;
    }
}

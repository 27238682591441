.change-method-button {
    color: rgb(128, 128, 128);
    text-decoration: underline;
    width: fit-content;
    background-color: transparent;
    border: none;
    outline: none;

    &:hover {
        color: rgb(61, 61, 61);
        cursor: pointer;
    }
}
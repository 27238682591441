@import "../scss/partials/colors";

#winback-fallback-page {
    margin-top: 100px;
    min-height: 250px;

    .title {
        font-size: 1.3em;
        margin-bottom: 0.5em;
    }

    .flex-stacked {
        display: flex;
        flex-flow: column nowrap;
        justify-content: center;
        align-items: center;
    }

    p,
    .content-text {
        text-align: justify;
    }
}

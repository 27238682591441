.resurs-section {
    .resurs-logo {
        height: 35px;
        padding: 0 3px;
    }

    .resurs-section__terms {
        margin: 1.5em 0 2em 0;
    }
}

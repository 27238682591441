@import "../scss/partials/all.scss";

#not-found {
    height: 70vh;
    display: flex;
    align-items: center;
    justify-content: center;

    #not-found-container {
        #not-found-header {
            color: $green-primary;

            h1 {
                font-size: 115pt;
                font-weight: 800;
            }
        }

        #not-found-text {
            p {
                font-size: 16pt;
                font-weight: 800;
                color: $blue-second;
            }
        }

        .img-container {
            &.picture {
                z-index: -1;
                position: absolute;

                img {
                    height: 100px;
                }

                &#cone-1 {
                    top: 40%;
                    left: 15%;

                    @media screen and (max-width: $breakpoint-tables) {
                        position: relative;
                        left: unset;
                        right: unset;
                        text-align: center;

                        img {
                            padding-right: 40px;
                        }
                    }
                }

                &#cone-2 {
                    top: 60%;
                    right: 20%;

                    @media screen and (max-width: $breakpoint-tables) {
                        display: none;
                    }
                }

                &#easel {
                    top: 15%;
                    right: 20%;

                    @media screen and (max-width: $breakpoint-tables) {
                        display: none;
                    }

                    img {
                        height: 250px;
                    }
                }
            }
        }
    }
}

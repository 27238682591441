@import "../../scss/partials/all.scss";

.error-title-text-container {
    text-align: center;
    .title {
        color: $red-error;
        font-weight: bold;
        margin-bottom: 40px;
    }

    .text {
        color: black;
    }
}

.brand-selection {
    &.brand-selection--forced {
        background-color: #fef5d1;
        padding: 4px 10px;

        &.brand-selection--force-top-space {
            margin-top: 10px;
        }
    }
    .brand-selection__description {
        font-size: 0.8em;
        cursor: default;
        color: #555555;
    }
    .brand-selection__link-text {
        font-size: 0.8em;
        cursor: pointer;
        text-decoration: underline;
        color: #555555;
        user-select: none;
    }
}

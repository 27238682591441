@import "partials/all.scss";
.swiipe-outer-container {
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    min-height: 100vh;

    font: 100% $font-stack;
    background-color: transparent;
    color: $blue-main;
    overflow: hidden;
}

.swiipe-outer-container,
.swiipe-modal-container {
    .pointer {
        cursor: pointer;
    }
}

.apple-pay-section {
    $apple-pay-button-width: 285px;
    $apple-pay-button-height: 45px;
    $apple-pay-button-border-radius: 5px;
    $apple-pay-button-padding: 0;

    width: 100%;
    min-height: 30px;
    margin: 40px 0 80px 0;

    .apple-pay-btn {
        display: block;
        margin: 0 auto 15px auto;
        
        background-color: black;
        border: none;
        color: white;
        cursor: pointer;
        font-synthesis: none;
        justify-content: center;
        min-height: 30px;
        height: $apple-pay-button-height;
        min-width: 140px;
        max-width: $apple-pay-button-width;
        width: 100%;
        border-radius: $apple-pay-button-border-radius;
        padding: $apple-pay-button-padding;
        -moz-font-feature-settings: kern;
        -moz-osx-font-smoothing: 'grayscale';
        -webkit-font-smoothing: antialiased;

        .apple-pay-btn__inside {
            display: flex;
            align-items: center;
            justify-content: center;
            font-weight: 600;
            line-height: 1.2;

            img {
                margin-left: 5px;
                height: $apple-pay-button-height;
            }
        }
    }
}
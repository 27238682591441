.a2a-payment-type-icon {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: flex-end;
    img {
        margin-left: 10px;
        margin-top: 5px;
        margin-bottom: 5px;
    }

    .a2a-payment-type-icon__more {
        margin-left: 10px;
        font-size: 12px;
        font-family: sans-serif;
    }
}

.swiipe-form-footer.form-footer {
    p {
        font-size: 16px !important;
        margin-bottom: 0px;
    }

    .secured-by-swiipe {
        height: 19px;
        vertical-align: top;
        padding-bottom: 1px;
    }
}

#credt-card-fields {
    #hosted-field-container-creditcard {
        width: 300px;
        width: 100%;
    }

    #hosted-field-container-cvv {
        width: 49%;
        float: right;
        @media screen and (max-width: 479px) {
            float: unset;
            width: 100%;
        }
    }

    #hosted-field-container-date-expire {
        width: 49%;
        @media screen and (max-width: 479px) {
            width: 100%;
        }
    }

    #hosted-field-container-date-expire,
    #hosted-field-container-cvv {
        display: inline-block;
    }

    .hosted-input-container {
        background: transparent !important;
    }

    .hosted-field-container {
        iframe {
            height: 130px;
            border: none;
            width: 100% !important;

            max-width: 100%;
            min-width: auto;
        }
    }
}

.credit-card-section {
    .credit-card-section__card-logo {
        height: 50px;
        padding: 0 3px;
    }

    .credit-card-section__reservation-notice {
        width: 100%;
        color: grey;
        display: block;
        margin: 0 0 20px 0;
        font-size: 14px;
    }

    .credit-card-section__brand-selection {
        position: relative;
        top: -1em;
    }
}

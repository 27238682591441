@import "../../../scss/partials/_all.scss";

.swiipe-modal-header {
    justify-content: center;
}

.errormessage-text {
    color: #1a496d;
    padding: 0.4rem;
}

.swiipe-modal-container {
    .modal-backdrop {
        opacity: 0.75;
    }

    .modal-body {
        #table-of-content {
            a {
                text-decoration: none;
                font-weight: inherit;
            }
        }

        a {
            text-decoration: none;
            color: $blue-header;
            font-weight: 600;

            &:hover {
                text-decoration: none;
                color: $blue-link-hover !important;
            }
        }
    }

    .close-cross {
        position: absolute;
        top: 26px;
        right: 20px;
        cursor: pointer;
        transition: 0.5s ease;

        &:hover {
            transform: scale(1.1);
        }
    }

    &.no-borders-inside {
        .modal-header {
            border-bottom: none;
        }

        .modal-footer {
            border-top: none;
        }
    }
}

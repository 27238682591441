.payment-section {
    transition: opacity 0.7s;
    opacity: 0;

    .payment-section__header {
        display: flex;
        justify-content: space-between;
        margin-top: 1.5em;

        .payment-section__header-title {
            font-weight: bold;
            color: black;
            font-size: 18px;
        }

        .payment-section__header-logos {
            align-items: center;
        }
    }

    a {
        color: #006464;
        text-decoration: underline;
    }

    a:visited {
        color: #006464;
        text-decoration: underline;
    }
}

.active {
    opacity: 1;
}

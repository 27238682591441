.checkPaymentStatusRedirectPage {
    display: flex;
    flex-direction: column;
    height: 100vh;
    background-color: #fafafa;

    .spinner-container {
        display: flex;
        flex-grow: 1;
    }
}

.payment-type-button {
    .payment-method-button {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: center;
        padding: 1em 1.5em 1em 1.5em;
        margin-bottom: 10px;
        border-radius: 10px;
        border-top: 0.5px solid #b3b3b3;
        border-bottom: 0.5px solid #b3b3b3;
        text-align: left;
        box-shadow: 0 3px 3px rgba(0, 0, 0, 0.16), 0 3px 4px rgba(0, 0, 0, 0.23);
        cursor: pointer;
        transition: 0.02s ease-in-out all;
    }

    .payment-method-logo {
        max-height: 30px;
        margin-left: 5px;
    }
    .payment-method-button:hover {
        transform: scale(1.03);
        background-color: rgb(240, 248, 255);
    }
    .payment-radio-button {
        margin-right: 0.5em;
    }
    .payment-radio-button:hover {
        margin-right: 0.5em;
        cursor: pointer;
    }
    .payment-method-button-reservation-notice {
        margin: 5px 0 0 23px;
        width: 100%;
        color: grey;
        font-size: 12px;
        @media screen and (max-width: 576px) {
            text-align: left;
        }
    }
    .payment-type-button__brand-selection {
        width: 100%;
        margin-left: 23px;
    }
}

@import "../../scss/partials/all.scss";

.title-text-container {
    text-align: center;
    .title {
        font-weight: bold;
        margin-bottom: 40px;
    }

    .text {
        color: grey;
        font-size: 14px;
    }
}

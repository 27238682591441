.payment-credit-card-logos {
    display: flex;
    justify-content: flex-end;
    height: 30px;
    min-width: 0;

    .credit-card-logo {
        margin-left: 3px;
        height: 100%;
    }
}

@import "../scss/partials/colors";

#payment-page {
    .payment-page-methods {
        padding-top: 0.5em;
        align-items: center;
    }

    .payment-page-header {
        display: flex;
        justify-content: space-between;
        color: $gray-font;
        font-size: 18px;
        font-weight: bold;

        .amount-header {
            color: #797676;
        }
    }

    .payment-page-header__description {
        color: rgb(43, 43, 43);
        font-weight: 600;
        font-size: 16px;
        margin: 1.5em 0 1em 0;

        &.payment-page-header__description--unbold {
            font-weight: normal;
        }
    }

    .pay-button {
        border-radius: 7px;
    }

    .cancel-button-container {
        width: 100%;
        display: flex;
        justify-items: center;
        align-items: center;
        margin-top: 40px;

        .helper {
            flex: 1 0 0px;
        }
    }
    .cancel-button {
        background-color: rgb(226, 226, 226) !important;
        flex: 0 0 250px;
        color: black;
        font-size: 13px;
        border-radius: 7px;
    }
}
